import { Select } from "antd";
import dayjs from "dayjs";
import dayLocaleData from "dayjs/plugin/localeData";

dayjs.extend(dayLocaleData);

type DatePickerHeaderProps = {
  value: dayjs.Dayjs;
  onChange: (date: dayjs.Dayjs) => void;
};

const SHOW_PAST_YEARS = 124;
const SHOW_FUTURE_YEARS = 5;

const DatePickerHeader = ({ value, onChange }: DatePickerHeaderProps) => {
  const start = 0;
  const end = 12;
  const monthOptions = [];

  let current = dayjs();
  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i++) {
    current = current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option
        key={i}
        value={i}
        label={months[i]}
        className="text-primary"
      >
        {months[i]}
      </Select.Option>
    );
  }

  const year = current.year();

  const options = [];
  for (let i = year - SHOW_PAST_YEARS; i <= year + SHOW_FUTURE_YEARS; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="text-primary">
        {i}
      </Select.Option>
    );
  }
  return (
    <div style={{ padding: 8 }}>
      <div className="flex flex-row">
        <div className="flex-1">
          <Select
            showSearch
            className="mr-2 !w-24 py-2"
            value={value.year()}
            onChange={(newYear) => {
              const now = value.clone().year(newYear);
              onChange(now);
            }}
          >
            {options}
          </Select>
          <Select
            optionFilterProp="label"
            showSearch
            size="small"
            className="!w-20 py-2"
            value={value.month()}
            onChange={(newMonth) => {
              const now = value.clone().month(newMonth);
              onChange(now);
            }}
          >
            {monthOptions}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default DatePickerHeader;
