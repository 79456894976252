import { Button } from "antd";
import { ButtonProps } from "antd/lib";
import * as React from "react";
import { SC_BUTTON_TYPE } from "../../../models/constants/core.constants";

export type SCButtonProps = ButtonProps & {
  scType?: string;
};

const SCButton: React.FC<SCButtonProps> = (props: SCButtonProps) => {
  const { scType, className, ...restInputProps } = props;
  const [scClassName, setScClassName] = React.useState(
    " !capitalize primary-btn custom-sm:h-[40px] font-bold "
  );
  const reSetScClassName = React.useCallback(
    (type: string) => {
      let common = " !capitalize primary-btn custom-sm:h-[40px] font-bold ";
      let submit_common =
        " !capitalize primary-btn custom-sm:h-[40px] font-bold font-secondary ";
      switch (type) {
        case SC_BUTTON_TYPE.PRIMARY:
          setScClassName(className + common);
          break;
        case SC_BUTTON_TYPE.SECONDARY:
          setScClassName(
            className +
              " !capitalize secondary-btn custom-sm:h-[40px] font-bold "
          );
          break;
        case SC_BUTTON_TYPE.SUBMIT_PRIMARY:
          setScClassName(className + submit_common);
          break;
        case SC_BUTTON_TYPE.PRICING_BACK:
          setScClassName(className + common + " w-[110px] ");
          break;
        case SC_BUTTON_TYPE.UPLOADBUTTON:
          setScClassName(
            className +
              " capitalize bg-white rounded-3xl h-[48px] sm:px-[20px] px-2 "
          );
          break;
        case SC_BUTTON_TYPE.SUBMIT_UPLOADBUTTON:
          setScClassName(
            className +
              " bg-white rounded-3xl h-[48px] px-[20px] font-bold capitalize font-secondary "
          );
          break;
        case SC_BUTTON_TYPE.CLEAR_FORM:
          setScClassName(className + common + " mt-[10px] ");
          break;
        case SC_BUTTON_TYPE.KEYPAD:
          setScClassName("num-btn font-secondary");
          break;
        case SC_BUTTON_TYPE.EXPIRED:
          setScClassName(
            className + " !capitalize danger-btn custom-sm:h-[40px] font-bold "
          );
          break;
        case SC_BUTTON_TYPE.TYPELINK:
          setScClassName(
            "!p-0 text-left text-primary 2xl:text-xl lg:text-lg border-none text-base font-secondary tracking-[0.4px] font-medium hover:underline hover:text-primary !shadow-none !px-2"
          );
          break;
        default:
          setScClassName(className + common);
          break;
      }
    },
    [className]
  );
  React.useEffect(() => {
    reSetScClassName(scType || "");
  }, [scType, reSetScClassName]);

  return <Button className={scClassName} {...restInputProps} />;
};

export default SCButton;
