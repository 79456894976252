import { API_URLS } from "../../../core/models/enums/url.enums";
import axiosHttp from "../../../core/services/Interceptors";
import { GenericResponse } from "../../Teams/service/teamsServices";

const { REACT_APP_API_URL } = process.env;

export type GetEEPlanStatusPayload = {
  entityRecordKey: string;
};

export type GetEEPlanStatusResponse = {
  sectionName: string;
  sectionId: number;
  sectionStatus: number;
};

export const getEEPlanStatus = async (params: GetEntityByKeyPayload) => {
  const url = `${API_URLS.GET_EE_PLAN_STATUS.replace("{id}", params.entityRecordKey ?? "")}`;

  const response =
    await axiosHttp.get<GenericResponse<GetEEPlanStatusResponse>>(url);
  return response.data;
};

export type GetEntityByKeyPayload = {
  entityRecordKey: string;
};

export type GetEntityByKeyResponse = {
  entityRecordKey: string;
  entityName: string;
  sectionId: number;
  isDefault: boolean;
  sectionStatus: number;
  userPermission: entityUserPermissionType[];
};

export const getEntityByKey = async (params: GetEntityByKeyPayload) => {
  const response = await axiosHttp.get<GenericResponse<GetEntityByKeyResponse>>(
    REACT_APP_API_URL + API_URLS.BASE_ENTITY + API_URLS.GET_ENTITY_BY_KEY,
    {
      params,
    }
  );
  return response.data;
};

export type GetEntityListResponse = {
  entityRecordKey: string;
  entityName: string;
  sectionId: number;
  isDefault: boolean;
  sectionStatus: number;
};
export const getEntities = async () => {
  const response = await axiosHttp.get<
    GenericResponse<GetEntityListResponse[]>
  >(REACT_APP_API_URL + API_URLS.BASE_ENTITY + API_URLS.GET_ALL_ENTITY);
  return response.data;
};

export type CreateEntityPayload = {
  entityName: string;
  entityUserPermission: entityUserPermissionType[];
};

export type entityUserPermissionType = {
  userIds: string;
  canEdit: boolean;
  canView: boolean;
};

export const createEntity = async (params: CreateEntityPayload) => {
  const response = await axiosHttp.post<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.BASE_ENTITY + API_URLS.CREATE_ENTITY,
    params
  );

  return response.data;
};

export type UpdateEntityPayload = {
  entityRecordKey: string;
  entityUserPermission: entityUserPermissionType[];
};

export const updateEntity = async (params: UpdateEntityPayload) => {
  const response = await axiosHttp.put<GenericResponse<void>>(
    REACT_APP_API_URL + API_URLS.BASE_ENTITY + API_URLS.UPDATE_ENTITY,
    params
  );
  return response.data;
};

export type DeleteEntityPayload = {
  entityKey: string;
};
export const deleteEntity = async (params: DeleteEntityPayload) => {
  const response = await axiosHttp.delete<GenericResponse<void>>(
    REACT_APP_API_URL +
      API_URLS.BASE_ENTITY +
      API_URLS.DELETE_ENTITY +
      "/" +
      params.entityKey,
    {
      data: { entityKey: params.entityKey },
    }
  );

  return response.data;
};

export type GetEmployeeListForManageEntityResponse = {
  userId: number;
  fullName: string;
  isCurrentUser: boolean;
};
export const getEmployeesToManageEntities = async () => {
  const response = await axiosHttp.get<
    GenericResponse<GetEmployeeListForManageEntityResponse[]>
  >(
    REACT_APP_API_URL +
      API_URLS.BASE_ENTITY +
      API_URLS.GET_EMPLOYEES_TO_MANAGE_ENTITY
  );
  return response.data;
};

export type AssociateUsersToEntityPayload = {
  entityKey: string;
  userIds: string[];
};

export type AssociateUsersToEntityResponse = {
  userIds: string;
  canEdit: boolean;
  canView: boolean;
};

export const associateUsersToEntity = async (
  params: AssociateUsersToEntityPayload
) => {
  const url = `/${API_URLS.ASSOCIATE_USERS_TO_ENTITY?.replace("{id}", params.entityKey ?? "")}`;

  const response = await axiosHttp.post<GenericResponse<void>>(url, params);

  return response.data;
};
